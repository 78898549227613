<template>
    <modal @close="toggleModalActive" :modalActive="modalActive">
        <div class="modal-content">
            <img :src="selectedImg" class=" full-size-image" @click="toggleModalActive" />
        </div>
    </modal>
    <div v-show="!modalActive">
        <div class="mt-0 mx-auto px-8">
            <div class="p-1 md:flex flex-col relative w-max text-color tracking-widest" style="display: flex; align-items: center">
    <span class="text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl">T-{{timer.minutes.value.toString().padStart(2, '0')}}:{{timer.seconds.value.toString().padStart(2, '0')}}</span>
</div>

            <div class="grid grid-cols-31 gap-4 items-center md:grid-cols-3">
                <div class="justify-self-end hidden md:block">
                    <img src="../assets/handvictor-8.png" class="w-max rotate-90" />
                </div>
                <div class="justify-self-start md:justify-self-center flex flex-row md:flex-col items-center">
                    <div class="player-turn-img m-4">
                        <img :src="thisItemAvatar" class="h-full" />
                    </div>
                    <div class="text-center">
                        <h1 class="sub-heading-3 small-text text-color" v-show="!gettingScore ">
                            <span class="color-blueseil">{{thisItemDisplayName || null}}</span>
                            <span class="color-blueseil">'s submission </span>
                        </h1>
                        <div class="lds-facebook" v-show="gettingScore || allGuessesMade">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
                <div class="justify-self-start hidden md:block">
                    <img src="../assets/handvictor-8.png" class="w-max -rotate-90" />
                </div>
            </div>
        </div>

        <div class="items-end px-5 my-5 justify-items-center">
  <div class="col-span-12 lg:col-span-8 order-first lg:order-none">
    <div class="my-2">
      <div class="flex justify-center gap-x-2">
        <div v-for="(image, index) in thisItem.images" :key="index">
          <div class="image-container" @click="selectImg(image.image, index)">
            <img :src="image.image" class="w-full h-full object-cover fixed-size-image" />
            <img src="@/assets/binocular.png" class="image-icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


        <div class="container mt-3.5 md:mt-8 mx-auto px-8">
            <div>
                <h1 class="sub-heading-3 medium-text-3 text-color tracking-widest text-center">
                    <span style="white-space: pre-line">{{headerText}}</span>
                </h1>
            </div>
            <div class="text-center">
                <div>
                    <div class="flex justify-center items-center">
                        <textarea type="text" class="bg-transparent input-field my-4" style="height: 240px; width: 100%;" id="my-textarea" v-model="scoreResponse" v-show="!showPrompt && showScore && !allGuessesMade" readonly></textarea>
                    </div>
                    <button type="button" class="text-white bg-btn-primary border-3 px-10 py-3 border-white w-auto" @click="getNextSet()" v-show="!showPrompt && showScore && !showDonate && !allGuessesMade"> PROCEED </button>
                    <div class="flex justify-center items-center" v-show="showPrompt && !showScore">
                        <textarea type="text" class="bg-transparent input-field my-4" style="height: 180px; width: 100%;" id="my-textarea" placeholder="Put the movie title here, or describe the movie e.g. That one movie with John Travolta where he dances in the disco" v-model="guess"></textarea>
                        <button type="button" class="text-white bg-btn-primary border-3 px-3 py-3 border-white w-auto" @click="submitGuess(guess)" v-show="showPrompt && !showScore && !allGuessesMade"> GO! </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
  import {
    ref,
    computed,
    reactive,
    watchEffect,
    onMounted,
    onUpdated,
    watch,
    onBeforeMount
} from 'vue';
import {
    useTimer
} from 'vue-timer-hook';
import {
    useStoreGame
} from '@/stores/storeGame'
import modal from '@/views/components/modal.vue';

import 'vue-lite-youtube-embed/style.css'
import {
    useRouter,
    onBeforeRouteLeave
} from 'vue-router'
import {
    useStoreAuth
} from '@/stores/storeAuth'
const modalActive = ref(false);
const toggleModalActive = () => {
    modalActive.value = !modalActive.value;
};
const showPhotos = ref(true);
const myStoreAuth = useStoreAuth();

const headerText = ref('GUESS THE MOVIE TITLE');
const showScore = ref(false);
const showDonate = ref(false);
const gettingScore = ref(false);
const showPrompt = ref(true);
const router = useRouter();
const storeGame = useStoreGame();
const scoreResponse = ref('');
const guess = ref('');
const allGuessesMade = ref(false);
const allPlayersGuessingCompleted = ref(false);
const dataset = storeGame.playerImgSubmissions;
const dataLength = dataset.length;
const imgUser = storeGame.playerImgSubmissions[1].user;
const scoresSubmitted = ref(false);

const thisItem = ref(dataset.find(item => item.user !== myStoreAuth.user.id));
//console.log('this items user:', thisItem.value.user);
//console.log('current user ', myStoreAuth.user.id);

const thereIsMore = computed(() => {
    return dataset.indexOf(thisItem.value) !== dataLength;
});

const checkAllPlayersGuessingCompleted = () => {
    //compare logic here
    const playerCount = storeGame.playerImgSubmissions.length;
    const competitorPerPlayer = playerCount -1;
    const allScoreCount = storeGame.allScores.length;
    return playerCount * competitorPerPlayer == allScoreCount;
}

const runWhenAllPlayersGuessingCompleted = () => {
    // add the routing stuff here
    //ok
    confirmNavigation.value = true;
    router.push({ name: 'Result'});
}

watch (
    () => [storeGame.playerImgSubmissions,storeGame.allScores],
    () => {
        allPlayersGuessingCompleted.value = checkAllPlayersGuessingCompleted();
        if (allPlayersGuessingCompleted.value) {
            runWhenAllPlayersGuessingCompleted();
        }
    }
)

const selectedImg = ref('');
const time = new Date();
time.setSeconds(time.getSeconds() + 300); // 10 minutes timer
const timer = useTimer(time);

const selectImg = (imgUrl, index) => {
    selectedImg.value = imgUrl;
    //console.log(`Image ${index} was selected.`);
    toggleModalActive();
    // Open modal with selectedImg.value
}
onMounted(() => {
    watchEffect(async () => {
        if (timer.isExpired.value) {
            console.warn('IsExpired');

            confirmNavigation.value = true;
            if (!scoresSubmitted.value){
            await storeGame.submitScores();}
            router.push({
                name: 'Result'
            });

        }
    })
})

const getNextItem = () => {
    //console.log('running next');
    let index = dataset.indexOf(thisItem.value) + 1;
    while (index < dataLength && dataset[index].user === myStoreAuth.user.id) {
        index++;
    }
    if (index < dataLength) {
        thisItem.value = dataset[index];
        //console.log('getting next: not at end');
        return true;

    } else {
        //console.log('reached the end');
        return false;
    }
};


const thisItemAvatar = computed(() => {
    const player = storeGame.game.players.find(player => player.id === thisItem.value.user);
    return player ? player.photoURL : null;
});

const thisItemDisplayName = computed(() => {
    const player = storeGame.game.players.find(player => player.id === thisItem.value.user);
    if (player) {
        return player.displayName || player.email.split('@')[0];
    } else {
        return null;
    }
});

const thisItemUserId = computed(() => {
    const player = storeGame.game.players.find(player => player.id === thisItem.value.user);
    if (player) {
        return player.id;
    } else {
        return null;
    }
});

const submitGuess = async (guessText) => {
    gettingScore.value = true;
    showPhotos.value = false;
    showPrompt.value = false;
    headerText.value = 'Conversing with AI.....'
    //console.log('guess: ', guessText);
    const actualMovieTitle = thisItem.value.movie;
    //console.log('movie: ', actualMovieTitle);
    const thisguess = {
        actualMovieTitle,
        guessText,
    };
    const result = await storeGame.processGuess(thisguess);

    showScore.value = true;
    const parsedResult = result;
    console.log('about to change the headerText');
    headerText.value = 'Actual Movie:\n ' + actualMovieTitle + '\n You Scored: ' + parsedResult.score
    console.log(headerText.value);
    //console.log('result from chatgpt into the vue component: ', result);
    scoreResponse.value = parsedResult.reason;
    //console.log(guess.value);
    gettingScore.value = false;
    const imgUserPenalty = computePenalty();

    //add any future bonus scores / penalties here 
    const scoreInfo = {
        guessUser: myStoreAuth.user.id,
        imgUser: thisItemUserId.value,
        guess: guessText,
        score: parsedResult.score,
        imgUserPenalty: imgUserPenalty

    }
    //console.log('could this be the problem does thisItemUserId =',thisItemUserId.value, ' and thisitem.user = ', thisItem.value.user);
    storeGame.scores.push(scoreInfo)
    //headerText.value = 'GUESS THE MOVIE TITLE';

    // set the v-model of the input field to the value returned by processGuess
}

const computePenalty = () => {
    let penalty = 0;
    //console.log('images from this itme:', thisItem.value.images.length);
    for (let image of thisItem.value.images) {
        penalty += image.penaltyWords;
    }

    return penalty
}

const getNextSet = async () => {
    //console.log('item index now before more = ', dataset.indexOf(thisItem.value));
    //get the next players images if any exist
    const more = getNextItem();
    //console.log('item index now after more = ', dataset.indexOf(thisItem.value));
    //console.log('more', more);
    if (more) {
        showPrompt.value = true;
        showScore.value = false;
        headerText.value = 'GUESS THE MOVIE';
        guess.value = '';
        scoreResponse.value = '';
        showPhotos.value = true;
    } else {
        allGuessesMade.value= true;
        headerText.value="Waiting for all player to complete their guesses"
        //figure out how to wait for all of the other users here
        // active player when guessing started * (active players - 1) = number of guesses
        //if score count < above wait... otherwise router.push
       
        
        await storeGame.submitScores();
        scoresSubmitted.value = true;

      
    }
}


const newGame = () => {
    confirmNavigation.value = true;
    storeGame.allUsersComplete = false;
    router.push({
        name: 'StartGame'
    });
}
const confirmNavigation = ref(false);
onBeforeRouteLeave((to, from, next) => {
    if (confirmNavigation.value) {
        // Allow the navigation to proceed
        next();
    } else {
        // Prevent the navigation and display a confirmation dialog
        const message = 'Are you sure you want to leave this page?';
        if (confirm(message)) {
            // Navigate to a different page when the player confirms the navigation
            confirmNavigation.value = true;
            router.push({
                name: 'StartGame'
            });
        } else {
            next(false);
        }
    }
});
  
  
</script>

<style>
mark {
  background-color: yellow;
}
.text-red-600 {
    color: red;
    font-weight: bold;
  }
  .border-pink {
  border-color: #e74c3c;
  overflow: hidden;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.youtube-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* or "cover" to crop the thumbnail */
}
.new-movie-img {
    width: 140px;
    height: 50px;
    margin: 4px;
    padding: 4px;
   
  }

  @media (max-width: 768px) {
    .new-movie-img {
      width: 30px;
      height: 25px;
    }
  }
  .fixed-size-image {
  width: 150px;
  height: 150px;
}
.small-text {
  font-size: 1.5rem;
}
textarea {
  font-size: 10px;
  line-height: 1.25 !important;
}
.fixed-size-image {
    width: 150px !important;
    height: auto;
}
.main-btn {
  width: auto !important;
  font-size: 10px;
}
.xwords {
  font-size: 10px !important;

}
.penalty-words-label {
  color: rgb(162, 162, 207);
}
@media (max-width: 767px) {
    .movie-image {
      height: 190px !important;
    }
  }
  .image-container {
  position: relative;
}

.image-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
}


</style>

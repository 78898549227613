import { defineStore } from "pinia";
import {
  collection,
  onSnapshot,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  addDoc,
  query,
  orderBy,
} from "firebase/firestore";

import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "@/js/firebase";
import { useStoreAuth } from "@/stores/storeAuth";
import { watch } from "vue";
import { useRouter } from "vue-router";
//import { QuerySnapshot } from "@google-cloud/firestore";

//
const functions = getFunctions();
let gameDocRef;
let userGameDataRef;
let getGameSnapshot = null;
let getUserDataSnapShot = null;
let allScoresSnapShot = null;

export const useStoreGame = defineStore("storeGame", {
  state: () => {
    return {
      allScores: [],
      allUsersComplete: false,
      createdBy: null,
      currentImages: [],
      currentPhase: 1,
      currentPrompt: "",
      currentRound: 1,
      game: {
        player: [],
        started: false,
        creatorOnline: true,
      },
      gameId: null,
      generatingImages: false,
      lateToGame: false,
      movieLoaded: false,
      newGameLoading: false,
      newRoundLoading: false,
      numOfImages: 3,
      numOfPhases: 3,
      numOfRounds: 3,
      phaseReady: false,
      playerCount: 0,
      playerImgSubManual: false,
      playerImgSubmissions: [],
      players: [],
      promptReady: false,
      round1: {},
      round1Images: [],
      round2: {
        test: "frank",
        test2: ["ran", "cow", "bird"],
      },
      roundReady: false,
      scores: [],
      started: false,
      waitingForGuessing: false,
    };
  },
  actions: {
    async createGame(isPrivate) {
      this.newGameLoading = true;
      const authStore = useStoreAuth();
      //console.log("creating game...");
      //var userId = authStore.user.uid;
      const generateUniqueId = httpsCallable(functions, "generateUniqueId");
      //console.log(authStore.user.photoURL);
      generateUniqueId({
        id: authStore.user.id,
        displayName: authStore.user.displayName,
        photoURL: authStore.user.photoURL,
        email: authStore.user.email,
        isPrivate: isPrivate,
      })
        .then((result) => {
          //console.log("results from gcf: ", result.data);
          this.gameId = result.data.uniqueID;
          //console.log("set the game id in the store to: ", this.gameId);

          //initialize the game snapshot
          //gameDocRef = collection(db, "games", this.gameId);
          gameDocRef = doc(db, "games", this.gameId);
          //console.log("got the game collection ", gameDocRef);
          this.getGame();
          this.newGameLoading = false;
        })
        .catch((error) => {
          const code = error.code;
          const message = error.message;
          const details = error.details;
        });
    },
    getPlayerCount() {
      if (this.players) {
        //console.log("players: ", this.players.length);
        this.playerCount = this.players.length;
      }
      //console.log("no players yet");
      this.playerCount = 0;
    },
    async getGame() {
      //console.log("getting the game snapshot now");
      if (getGameSnapshot) getGameSnapshot(); // unsubscribe other users who may have logged in earlier
      getGameSnapshot = onSnapshot(gameDocRef, (gameSnapShot) => {
        //console.log("gameSnapshot :", gameSnapShot);
        this.game = gameSnapShot.data();
        if (gameSnapShot.data().allUsersComplete === true) {
          this.allUsersComplete = true;
        }
      });
    },
    async checkGameCode(gameCode) {
      //console.log("gamecode received: ", gameCode);
      const docRef = doc(db, "games", gameCode);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        //check to see if we have a late arrival to the game
        //console.log("has the game started:", docSnap.data().started);
        if (docSnap.data().started) {
          this.lateToGame = true;
        }

        return true;
      }
      return false;
    },
    async addPlayerToGame(gameCode) {
      //add call to cloud function to add the player
      const authStore = useStoreAuth();
      //console.log("client addplayer uid: ", authStore.user.id);
      //console.log("client addplayer gameCode: ", gameCode);
      const player = {
        gameId: gameCode,
        userId: authStore.user.id,
        displayName: authStore.user.displayName,
        photoURL: authStore.user.photoURL,
        email: authStore.user.email,
      };
      const addplayer = httpsCallable(functions, "addPlayerToGame");
      addplayer(player)
        .then((result) => {
          //console.log("results from gcf: ", result.data);

          //initialize the game snapshot
          //gameDocRef = collection(db, "games", this.gameId);
          gameDocRef = doc(db, "games", gameCode);
          //console.log("got the game collection ", gameDocRef);
          this.gameId = gameCode;
          this.getGame();
          //this.newGameLoading = false;
        })
        .catch((error) => {
          const code = error.code;
          const message = error.message;
          const details = error.details;
        });
    },
    async startGame() {
      gameDocRef = doc(db, "games", this.gameId);
      // Assuming `gameDocRef` is a valid reference to a document in Firestore
      updateDoc(gameDocRef, { active: true })
        .then(() => {
          console.log("Document updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      //console.log("game started");
      const gameId = this.gameId;
      const numOfRounds = this.numOfRounds;
      const numOfPhases = this.numOfPhases;
      if (!this.started) {
        const startGameFunction = httpsCallable(functions, "startGame");
        startGameFunction({ gameId, numOfRounds, numOfPhases })
          .then((respons) => {
            //console.log("game started");
            //get subscriptions
          })
          .catch((error) => {
            //console.log("error stargin game: ", error);
          });
      }
    },
    async watchForGameToStart() {
      watch(
        () => this.game.started,
        async (startedState) => {
          //this.currentRound = this.currentRound + 1;
          //this.currentPhase = this.currentPhase + 1;
          //console.log("the game has started from the watch");
          const authStore = useStoreAuth();
          const gameId = this.gameId;
          const round = this.currentRound;
          const userId = authStore.user.id;

          try {
            const result = await this.getRandomMovie({ gameId, round, userId });
            //console.log("getRandomMovie result:", result);
            // Do something with the result, if necessary
            await this.fetchUserGameData();
            this.roundReady = true;
          } catch (error) {
            console.error("Error in getRandomMovie:", error);
            // Handle the error, if necessary
          }
        }
      );
    },
    async getNewMovie() {
      const authStore = useStoreAuth();
      const gameId = this.gameId;
      const round = this.currentRound;
      const userId = authStore.user.id;

      try {
        const result = await this.getRandomMovie({ gameId, round, userId });
        //console.log("getRandomMovie result:", result);
        // Do something with the result, if necessary
        //this.fetchUserGameData();
      } catch (error) {
        console.error("Error in getRandomMovie:", error);
        // Handle the error, if necessary
      }
    },

    async fetchUserGameData() {
      if (getUserDataSnapShot) {
        getUserDataSnapShot();
      }

      //console.log("fetching");
      const authStore = useStoreAuth();

      userGameDataRef = doc(
        db,
        "games",
        this.gameId,
        "rounds",
        this.currentRound.toString(),
        "user_data",
        authStore.user.id
      );
      getUserDataSnapShot = onSnapshot(userGameDataRef, (userDataSnapShot) => {
        //const userGameData = await getDoc(userGameDataRef);
        //console.log("data:", userGameData.data());
        //getting this code to run when user submission data is included .. this is not necessary. consider changing.
        if (this.currentRound.toString() == "1") {
          this.round1 = userDataSnapShot.data();
          if (!this.allUsersComplete) {
            //console.log("about to get phase images based on new movie?");
            this.getPhaseImages();
          }
        }
        //console.log("about to update everyone's movies");
        this.round1 = userDataSnapShot.data();
      });
    },
    async submitPrompt() {
      //submitting request for images to be made
      const authStore = useStoreAuth();
      const gameId = this.gameId;
      const currentRound = this.currentRound;
      const userId = authStore.user.id;
      const phase = this.currentPhase;
      const prompt = this.currentPrompt;
      const imageOptionsPerPhase = this.numOfImages;
      const makeSubmission = httpsCallable(functions, "generateImages");
      try {
        const result = await makeSubmission({
          gameId,
          currentRound,
          userId,
          phase,
          prompt,
          imageOptionsPerPhase,
        });
        //console.log("getRandomMovie result:", result);
        // Do something with the result, if necessary
        //console.log("prompt submitted");
      } catch (error) {
        console.error("Error in submitting prompt for images:", error);
        // Handle the error, if necessary
      }
    },
    async loadAllMovies() {
      const startCount = 0;
      let count = startCount;
      let errors = [];

      const loadMoviesOnce = httpsCallable(getFunctions(), "loadMoviesOnce");

      for (let year = 1970; year <= 2020; year++) {
        try {
          const API_KEY = "k_u476hk11";
          const releaseDate = `${year}-01-01,${year}-12-31`;
          const url = `https://imdb-api.com/API/AdvancedSearch/${API_KEY}?title_type=feature&release_date=${releaseDate}&countries=us&languages=en&count=100`;
          await loadMoviesOnce({ url: url, startCount: count.toString() });
          count += 20;
          //console.log(`Movies loaded successfully for year ${year}`);
        } catch (error) {
          console.error(`Error loading movies for year ${year}:`, error);
          errors.push(error);
        }
      }

      if (errors.length === 0) {
        //console.log("All movies loaded successfully!");
      } else {
        console.error(errors);
      }
    },
    async getPhaseImages() {
      //console.log("getting phase images now: ");
      //console.log("images already? ", this.round1.sdImageQ_currentRound);
      //console.log("current round: ", this.currentRound);
      if (
        this.round1.sdImageQ_currentRound &&
        this.currentRound.toString() == "1"
      ) {
        const imageDocRef = doc(
          db,
          "sdImageQ",
          this.round1.sdImageQ_currentRound
        );
        const data = await getDoc(imageDocRef);
        //console.log("image q data: ", data);
        this.currentImages = data.data().output;
        this.generatingImages = false;
      } else {
        //console.log("no new images to get");
      }
    },
    async submitImages() {
      this.waitingForGuessing = true;
      //console.log("images submitted to game");
      const authStore = useStoreAuth();
      const gameId = this.gameId;
      const currentRound = this.currentRound;
      const userId = authStore.user.id;
      const images = this.round1Images;
      const makeSubmission = httpsCallable(functions, "acceptSubmissions");
      try {
        const result = await makeSubmission({
          gameId,
          currentRound,
          userId,
          images,
        });
        //console.log("submission of images result:", result.data);
        // Do something with the result, if necessary
        //console.log("images submitted");
      } catch (error) {
        console.error("Error in submitting prompt for images:", error);
        // Handle the error, if necessary
      }
    },

    async watchforAllUsersToSubmit() {
      watch(
        () => this.allUsersComplete,
        async (guessState) => {
          //console.log("the watch has indeed been triggered");
          if (guessState) {
            //console.log("the guessing phase has started");
            const authStore = useStoreAuth();
            const gameId = this.gameId;
            const round = this.currentRound;
            const userId = authStore.user.id;
            // get all of the users' images and store them in the array 'playerImgSubmissions'
            const collRef = collection(
              db,
              `games/${gameId}/rounds/${round}/user_data`
            );

            try {
              const snapshot = await getDocs(collRef);
              for (const doc of snapshot.docs) {
                const images = doc.data().round1ImageSubmissions;
                const movie = doc.data().movieTitle;
                const moviePoster = doc.data().movieImage;
                const imgUser = doc.id;
                const elementObj = {
                  user: imgUser,
                  movie: movie,
                  moviePoster: moviePoster,
                  images: images,
                };

                this.playerImgSubmissions.push(elementObj);
              }
              this.waitingForGuessing = false;
            } catch (error) {
              //console.log("error", error);
            }
          }
        }
      );
    },

    async processGuess({ actualMovieTitle, guessText }) {
      //send guess to the cloud and return the score and response to the client
      //console.log("function ", guessText);
      const getGPTResponse = httpsCallable(functions, "getChatGPTScore");
      const response = await getGPTResponse({ actualMovieTitle, guessText });
      //console.log("chatgpt response: ", response.data);
      const thisScore = convertStringToObject(response.data);
      const finalReason = thisScore.reason.replace(/[{}[\]]/g, "");
      //console.log(thisScore); // Logs the response from the Cloud Function
      return { score: thisScore.score, reason: finalReason };
    },

    async startAllScoresSnapShot() {
      //console.log("starting snaps for all scores");
      const gameRef = doc(db, "games", this.gameId);
      const scoresRef = collection(gameRef, "scores");
      allScoresSnapShot = onSnapshot(scoresRef, (QuerySnapshot) => {
        this.allScores = [];
        QuerySnapshot.forEach((doc) => {
          //console.log("query ss: ", doc.data());
          this.allScores.push(doc.data());
        });
      });
    },

    async submitScores() {
      console.log("sumitting a score now");
      const storeAuth = useStoreAuth();
      const gameRef = doc(db, "games", this.gameId);

      // Create a reference to the "scores" subcollection
      const scoresCollectionRef = collection(gameRef, "scores");

      for (const score of this.scores) {
        // Add a new document to the "scores" subcollection
        await addDoc(scoresCollectionRef, {
          player: storeAuth.user.id,
          score: score,
        });
        //call the watch of scores
        if (allScoresSnapShot) {
          allScoresSnapShot();
        }
        this.startAllScoresSnapShot();
      }
    },

    async getRandomMovie(gameInfo) {
      try {
        console.log("g,r,u", gameInfo.gameId, gameInfo.round, gameInfo.userId);
        // Generate a random number between 1 and 998
        const randomIndex = Math.floor(Math.random() * 998) + 1;
        //console.log("Random index:", randomIndex);
        //console.log("Type of random index:", typeof randomIndex);

        // Get the movie document with the corresponding ID
        //const movieCol = collection(db, "movies");
        //const movieDocRef = doc(movieCol, randomIndex.toString());
        const movieDocRef = doc(db, "movies", randomIndex.toString());
        const movie = await getDoc(movieDocRef);
        console.log("movie", movie.data());

        // Destructure the movie data
        const { title, image, excludedWords, trailer } = movie.data();
        console.log(title, image, excludedWords, trailer);

        // //Get a reference to the user data
        const userDataDoc = doc(
          db,
          "games",
          gameInfo.gameId,
          "rounds",
          gameInfo.round.toString(),
          "user_data",
          gameInfo.userId
        );

        // // Update the user data with the movie title, image, and excluded words
        await updateDoc(userDataDoc, {
          movieTitle: title,
          movieImage: image,
          excludedWords: excludedWords,
          trailerUrl: trailer,
        });

        //console.log("Movie data updated for user:", gameInfo.userId);
      } catch (error) {
        console.error(`Error in getRandomMovie: ${error}`);
      }
    },
  },
  getters: {},
});

function convertStringToObject(str) {
  // Use a regular expression to extract the score and reason from the string
  const regex =
    /(?:.*?\[\[score]]: )?(\d+), (?:\[\[reason]]: )?\[\[(.+?)\]\](?:.*?(?=\[\[score]]:)|$)|(?:.*?\[\[score]]: )(\d+), (?:\[\[reason]]: )(\S.+?\S)(?=\[\[score]]:|$)/s;
  const matches = str.match(regex);

  if (!matches) {
    throw new Error("Invalid string format");
  }

  // Determine which format was matched and extract the score and reason accordingly
  let score, reason;
  if (matches[1] && matches[2]) {
    // Format with double square brackets
    score = parseInt(matches[1], 10);
    reason = cleanUpReason(matches[2]);
  } else if (matches[3] && matches[4]) {
    // Format without brackets
    score = parseInt(matches[3], 10);
    reason = cleanUpReason(matches[4]);
  } else {
    // Format with single square brackets
    score = parseInt(matches[5], 10);
    reason = cleanUpReason(matches[6]);
  }

  // Construct the object using the extracted values
  const obj = {
    score,
    reason,
  };

  return obj;
}

function cleanUpReason(str) {
  // Remove the starting and ending double brackets and any whitespace
  const cleanedStr = str.replace(/^\[\[/, "").replace(/\]\]$/, "").trim();

  return cleanedStr;
}

// Please provide a JSON object that contains a score and a reason for the comparison of two movie titles. The two parameters are 'actualTitle' and 'guess', representing the actual title of a movie and a guess based on an image. The score should be on a scale from 1-100, where 100 is a perfect match and 0 is null or something obtusely different. Please do not discount any points based on simple misspellings nor on punctuation differences, but do give larger scores for related movies. Related movies include those that share common actors, directors, screenwriters, themes, genres, words/phrases in the title, or significant focus on a particular subject matter, such as dogs or other common themes.  Additional points are also scored if both movies were released in the same year. If two movies share one or more actors or directors, please give a higher score to reflect the increased likelihood of a correct guess. If the titles of the movies have words or phrases in common, please also consider this in the scoring.

// Please provide a clear and concise reason for the score that explains how you arrived at the final number. The reason should reference specific factors that contributed to the score.

// The input parameters are: {'actualTitle':'''Zabriskie Point" , 'guess': 'Cocaine Bear'}

// The expected output format is: {'score': score, 'reason': reason}" ONLY.  You will not provide any other text before or after the JSON object.  That is forbidden.

<template>
  <div class="container mx-auto px-8">
    <div class="mt-4 md:mt-8 text-center">
      <div class="flex flex-col items-center mb-5 md:mb-8">
        <img  src="../assets/handvictor-8.png" class="w-max" />
        <img src="../assets/Title.png" class="w-1/2 md:w-1/4 mt-4" />
      </div>

      <div class="text-center">
        <h1 class="sub-heading text-color tracking-widest">
          WELCOME, <span class="color-blueseil">{{storeAuth.user.displayName ? storeAuth.user.displayName.split(' ')[0] : storeAuth.user.email.split('@')[0]}}</span>


        </h1>
      </div>

      <div class="text-center pt-3">
        <div class="my-5">
          
            <button @click="() => getGameCode(true)"
              type="button"
              class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto"
            >
              New Private Game 
            </button>
           
          
        </div>
        <div class="my-5">
          
          <button @click="() => getGameCode(false)"
            type="button"
            class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto"
          >
          New Public Game
          </button>
         
        
      </div>
      <span class="sub-heading-2 color-pink tracking-wide py-5 " style="font-size: 0.8em;">
  OR
</span>


        <div class="my-5">
          <router-link to="EnterCode">
            <button type="button" class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto">
  Join a Game
  <div style="font-size: smaller; display: block;">(private or public)</div>
</button>

          </router-link>
         <div> <span
          class="sub-heading-2 color-pink tracking-wide py-5 " style="font-size: 0.8em;"
        >
          {{ availablePublicGames }} Public Games waiting for players
        </span></div>
        </div>
      </div>
      <div class="text-center mt-10">
        <span
          class="sub-heading-2 color-pink tracking-wide py-5 cursor-pointer" @click="showHowTo"
        >
          HOW TO PLAY?
        </span>
        <div v-if="storeGame.newGameLoading">
          <div class="lds-facebook"><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
   
  </div>
</template>

<script setup>
  import { useStoreGame } from '@/stores/storeGame'
  import { useStoreAuth } from '@/stores/storeAuth'
  import { useRouter } from 'vue-router'
  import { onMounted,onBeforeMount, onUnmounted, ref } from 'vue';
  import { collection, query, where, onSnapshot, orderBy, limit } from 'firebase/firestore';
  import { db } from '@/js/firebase';
  const storeGame = useStoreGame()
  const storeAuth = useStoreAuth()
  const router = useRouter()
  let gameQuerySnapshot = null;
  const publicGameCode = ref(null);
  const publicGameSize = ref(0);
  const waitingForPublicGames=ref(true);
  onBeforeMount(() => {
    //console.log ('start game mounted');
    // Reset storeGame when component is mounted
    storeGame.$reset();
  })
  const availablePublicGames = ref(0);

 const showHowTo = () => {
  window.open('https://www.youtube.com/watch?v=rc_bMgSNZnM', '_blank');
  }


const getGameCode = async (isPrivate) => {
  try {
    // Call createGame action with the isPrivate parameter
    storeGame.createGame(isPrivate);

    // Wait for newGameLoading to be set to false
    while (storeGame.newGameLoading) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    // Navigate to GameCode route
    router.push({ name: 'GameCode' });
  } catch (error) {
    console.error(error);
  }
}

onMounted(async() => {
  const gameCollectionRef = collection(db, 'games');
  const queryRef = query(
    gameCollectionRef,
    where('active', '==', false),
    where('creatorOnline', '==', true),
    where('isPrivate','==',false),
    orderBy('createdAt', 'asc'),
  );

  gameQuerySnapshot = await onSnapshot(queryRef, async (querySnapshot) => {
  waitingForPublicGames.value = true;
  console.log('Received a new snapshot of games:');
  availablePublicGames.value = querySnapshot.size;
  waitingForPublicGames.value = false;

  // Add code to update the document here
 
});

});

onUnmounted(() => {
  // Stop listening to the game query snapshot
  if (gameQuerySnapshot) {
    gameQuerySnapshot();
  }
});
</script>


<style scoped>
.spinner-grow[role="status"] {
  color: #ffffff; /* set the color to white */
  opacity: 0.8; /* set the opacity to 0.8 */
}
</style>

<template>
  <div  class="container mx-auto px-8">
    <div class="mt-4 md:mt-8 text-center" v-if="storeGame.game.creatorOnline">
      <div class="flex flex-col items-center mb-5 md:mb-8">
        <img src="../assets/handvictor-8.png" class="w-max" />
        <img src="../assets/Title.png" class="w-1/2 md:w-1/4 mt-4" />
      </div>
      <div v-if="!storeGame.lateToGame" >
        <div class="flex items-center justify-center">
        <div class="lds-facebook"><div></div><div></div><div></div></div>
         
      </div>
      <div>
        <h1 class="sub-heading medium-text text-color tracking-widest" style="font-size: 0.8rem;">
  Waiting for the game creator to start the game when at least 3 players are here ...
</h1>


      </div>
      <div>
        <h1 class="sub-heading text-color tracking-widest pt-10">CURRENT PLAYERS</h1>
        <div class="players-div py-5 mx-auto">
          <div class="grid grid-cols-3 gap-4">
            <div v-for="player in storeGame.game.players" :key="player.id" class="cols-1 justify-self-center">
              <div class="player-img">
                <img v-if="player.photoURL" :src="player.photoURL" class="" />
                <img v-else :src="playerAvatar(player)" class="" />
              </div>
              <div class="py-2 text-white players-text">{{ player.displayName || player.email.split('@')[0] }}</div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div v-if="storeGame.lateToGame" >
        <h1  class="sub-heading medium-text text-color tracking-widest"> Sorry... your friends started without you, or you navigated away from the game. </h1>
      </div>
      
    </div>
    <div class="mt-4 md:mt-8 text-center" v-if="!storeGame.game.creatorOnline">
      <div class="flex flex-col items-center mb-5 md:mb-8">
        <img src="../assets/handvictor-8.png" class="w-max" />
        <img src="../assets/Title.png" class="w-1/2 md:w-1/4 mt-4" />
     
        <h1  class="sub-heading medium-text text-color tracking-widest"> Sorry the Game's Creator Bailed before Starting the Game </h1>
      </div>
      <div class="text-center pt-3">
        <div class="my-5">
          
            <button @click="goBack"
              type="button"
              class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto"
            >
             GO BACK
            </button>
           
          
        </div>
        </div>
     
      
    </div>
  </div>

</template>

<script setup>
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { ref, watch } from 'vue';
import { useStoreGame } from '@/stores/storeGame';

const router = useRouter();
const storeGame = useStoreGame();


const playerAvatar = (player) => {
  if (player.photoURL) {
    return player.photoURL;
  }
}

watch(() => storeGame.roundReady, (newVal) => {
  if (newVal === true) {
    confirmNavigation.value = true;
    router.push({ name: 'Prompt' })
  }
});

//route guards
const confirmNavigation = ref(false);
onBeforeRouteLeave((to, from, next) => {
  if (confirmNavigation.value) {
    // Allow the navigation to proceed
    next();
  } else {
    // Prevent the navigation and display a confirmation dialog
    const message = 'Are you sure you want to leave this page?';
    if (confirm(message)) {
      // Navigate to a different page when the player confirms the navigation
      confirmNavigation.value = true;
      router.push({ name: 'StartGame' });
    } else {
      next(false);
    }
  }
});

const goBack = () => {
  confirmNavigation.value = true;
      router.push({ name: 'StartGame' });
}
</script>



<style scoped></style>

// Import all avatar files in the directory
const avatarFiles = require.context("@/assets/avatars", false, /\.png$/);

// Create an array of avatar objects with name and url properties
const avatars = avatarFiles.keys().map((fileName) => ({
  name: fileName.replace("./", "").replace(".png", ""),
  url: avatarFiles(fileName),
}));

// Export the array of avatar objects
export default avatars;

<template>
  <div class="container mx-auto px-8">
    <div class="mt-4 md:mt-8 text-center">
      <div class="flex flex-col items-center mb-5 md:mb-8">
        <img src="../assets/handvictor-8.png" class="w-max" />
        <img src="../assets/Title.png" class="w-1/2 md:w-1/4 mt-4" />
      </div>

      <div>
        <h1 class="sub-heading text-color tracking-widest">ENTER PRIVATE GAME CODE</h1>
        
      </div>
      <div v-if="gameCodeFound">
        <h1 class="sub-heading text-color tracking-widest">{{ gameCodeFound }}</h1>
      </div>
      
      <div>
        <input v-model="gameCode"
          type="text"
          placeholder="xxxx"
          class="bg-transparent input-field my-4"
        />
      </div>

      <div>
        
          <button @click="checkGameCode"
            type="button"
            class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto"
          >
            Join Private Game
          </button>
        
      </div>
     <div v-if="publicGameCode"> 
      <div>
        <h1 class="sub-heading text-color tracking-widest">OR</h1>
        
      </div>
      <div>
        
        <button @click="joinPublic"
          type="button"
          class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto"
        >
          Join A Public Game
        </button></div>
      
    </div>

      <!-- IF CONDITION FOR LOGIN ERROR -->
      <!-- <div>
        <h1 class="sub-heading text-error tracking-widest">WRONG INFO!</h1>
      </div> -->
      <!-- IF CONDITION ENDS HERE -->
    </div>
  </div>
</template>

<script setup>
import title from "./components/title.vue";
import { useStoreAuth } from "@/stores/storeAuth";
import { useStoreGame } from "@/stores/storeGame";
import { ref, onMounted, onUnmounted } from 'vue';
import { collection, query, where, onSnapshot, orderBy, limit } from 'firebase/firestore';
import { db } from '@/js/firebase';
import { useRouter } from 'vue-router';

const router = useRouter();
const storeGame = useStoreGame();
const gameCode = ref('');
const waitingForPublicGames = ref(true);
const gameCodeFound = ref('')
let gameQuerySnapshot = null;
const publicGameCode = ref(null);
const publicGameSize = ref(0);

const checkGameCode = async () => {
  if (await storeGame.checkGameCode(gameCode.value.toLowerCase())) {
    gameCodeFound.value = '';
    if (!storeGame.lateToGame) {
      await storeGame.addPlayerToGame(gameCode.value.toLowerCase())
      router.push({ name: 'Lobby' });
    } else {
      gameCodeFound.value = 'Sorry, your friends started without you - or you navigated away from the game'
      setTimeout(() => {
        router.push({ name: 'StartGame' });
      }, 10000); // 10000 milliseconds = 10 seconds
    }
  } else {
    gameCodeFound.value = 'Sorry, that Game Code is not valid. Please try again.'
    setTimeout(() => {
        router.push({ name: 'StartGame' });
      }, 10000); // 10000 milliseconds = 10 seconds
  }
}
const joinPublic = async () => {
  if (await storeGame.checkGameCode(publicGameCode.value)) {
    gameCodeFound.value = '';
    if (!storeGame.lateToGame) {
      await storeGame.addPlayerToGame(publicGameCode.value)
      router.push({ name: 'Lobby' });
    } else {
      gameCodeFound.value = 'Sorry, your friends started without you - or you navigated away from the game'
      setTimeout(() => {
        router.push({ name: 'StartGame' });
      }, 10000); // 10000 milliseconds = 10 seconds
    }
  } else {
    gameCodeFound.value = 'Sorry, that Game Code is not valid. Please try again.'
    setTimeout(() => {
        router.push({ name: 'StartGame' });
      }, 10000); // 10000 milliseconds = 10 seconds
  }

}

onMounted(async() => {
  const gameCollectionRef = collection(db, 'games');
  const queryRef = query(
    gameCollectionRef,
    where('active', '==', false),
    where('creatorOnline', '==', true),
    where('isPrivate','==',false),
    orderBy('createdAt', 'asc'),
    limit(1)
  );

  gameQuerySnapshot = await onSnapshot(queryRef, async (querySnapshot) => {
  waitingForPublicGames.value = true;
  console.log('Received a new snapshot of games:');
  querySnapshot.forEach((doc) => {
    console.log(`Game ID: ${doc.id}`);
    console.log(`Game data: ${JSON.stringify(doc.data())}`);
  });
  waitingForPublicGames.value = false;

  // Add code to update the document here
  if (querySnapshot.docs.length > 0) {
    const oldestGameDoc = querySnapshot.docs[0];
    const oldestGameData = oldestGameDoc.data();
    publicGameCode.value = oldestGameDoc.id;
    publicGameSize.value = oldestGameData.players.length;
    console.log('public game available:', publicGameCode.value);
    console.log('stuff:', publicGameSize.value);
  } else {publicGameCode.value = null}
});

});

onUnmounted(() => {
  // Stop listening to the game query snapshot
  if (gameQuerySnapshot) {
    gameQuerySnapshot();
  }
});
</script>


<style scoped></style>

<template>
  <div class="container mx-auto px-8">
    <div class="mt-4 md:mt-8 text-center">
      <div class="flex flex-col items-center mb-5 md:mb-8">
        <img src="../assets/handvictor-8.png" class="w-max" />
        <img src="../assets/Title.png" class="w-1/2 md:w-1/4 mt-4" />
      </div>

      <div v-show="storeGame.game.isPrivate">
        <div class="my-2" >
        <h1 class="sub-heading text-color tracking-widest">
          GAME CODE: <span class="color-pink">{{ storeGame.gameId }}</span>
        </h1>
      </div>

      <div>
        <h1 class="sub-heading-3 small-text text-color tracking-widest py-1">
          Share with your friends to play!
        </h1>
      </div></div>

      <div>
        <h1 class="sub-heading-3 small-text text-color tracking-widest py-1">
          Start the game when at least 3 players are here.  Game automatically begins upon 6 players joining.
        </h1>
      </div>
      
  
    <div class="players-div py-5 mx-auto">
      <div class="grid grid-cols-3 gap-4">
        <div v-for="player in storeGame.game.players" :key="player.id" class="cols-1 justify-self-center">
          <div class="player-img">
            <img v-if="player.photoURL" :src="player.photoURL" class="" />
            <img v-else :src="playerAvatar(player)" class="" />
          </div>
          <div class="py-2 text-white players-text">{{ (player.displayName || player.email.split('@')[0]).slice(0, 8) }}</div>

        </div>
        <div v-if="storeGame.newRoundLoading" class="items-center">
          <div class="lds-facebook"><div></div><div></div><div></div></div>
        </div>
      </div>
    </div>
   
      <div class="m-5">
        
        <button @click="startGame" v-if="storeGame.game.players?.length > 2 && !storeGame.newRoundLoading"
          type="button"
          class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto"
        >
          START!
        </button>
       
      
      </div>
      </div>
    </div>  
    
 
</template>

<script setup>
  import {  useRouter } from 'vue-router'
  import { useStoreGame } from '@/stores/storeGame'

  import { watch, ref } from 'vue';
  const storeGame = useStoreGame();
 
  const router = useRouter();
  const playerAvatar = (player) => {
    if (player.photoURL) {
      return player.photoURL;
    } 
  }
    /*
    store
  */
 


 
 const startGame = async () => {
  try {
    storeGame.newRoundLoading = true;
    // Call createGame action
    storeGame.startGame();
    
    // Wait for newGameLoading to be set to false
    while (!storeGame.game.started) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    while (!storeGame.roundReady) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    // Navigate to GameCode route
    storeGame.newRoundLoading = false;
    router.push({ name: 'Prompt' });
  } catch (error) {
    console.error(error);
  }
}
watch(() => storeGame.game , (newPlayersCount) => {
  if (newPlayersCount.players?.length === 6) {
  startGame();
}

})


</script>

<style scoped></style>

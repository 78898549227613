<template>
  <div style="display: flex; justify-content: center; align-items: flex-start; height: 100vh;">
    <div style="width: 350px; height: 350px; border: 1px solid black; display: flex; flex-direction: column; justify-content: center; align-items: center;" ref="adContainer">
      <p style="color: white; font-size: 14px; text-align: center;">This ad space could be yours. Contact ads@aigameboy.com for more information.</p>
    </div>
  </div>
</template>

  
  <script>
  export default {
    mounted() {
      this.loadAd();
    },
    methods: {
      loadAd() {
        // Infolinks PID and WSID
        window.infolinks_pid = 3390505;
        window.infolinks_wsid = 0;
  
        // Infolinks script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = '//resources.infolinks.com/js/infolinks_main.js';
        script.async = true;
  
        this.$refs.adContainer.appendChild(script);
      },
    },
  };
  </script>
  
<template>
  
  <router-view/>
</template>

<style>

</style>
<script setup>
/*
  imports
*/

  import { onMounted, onBeforeMount } from 'vue'
  //import NavBar from '@/components/Layout/NavBar.vue'
  import { useStoreAuth } from '@/stores/storeAuth'
  import {useStoreGame} from '@/stores/storeGame'
  //import { useStoreGame} from '@/stores/storeGame'

/*
  store
*/

  const storeAuth = useStoreAuth()
  const storeGame = useStoreGame()
  //const storeGame = useStoreGame()

/*
  mounted
*/

  onBeforeMount(() => {
    console.log("app.vue onmounted triggerd, running storeauth")
    storeAuth.init()
    storeGame.watchForGameToStart()
    storeGame.watchforAllUsersToSubmit()
  })
</script>

<style>

</style>
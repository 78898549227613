import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  getDatabase,
  ref,
  onValue,
  set,
  onDisconnect,
} from "firebase/database";
let firebaseConfig;
console.log("mode:", process.env.NODE_ENV);

// console.log("using test firebase");
// firebaseConfig = {
//   apiKey: "AIzaSyBGEciw54Y-Ob2UrZQC0a9Jats-rYa4-OA",
//   authDomain: "aigameboytest.firebaseapp.com",
//   databaseURL: "https://aigameboytest-default-rtdb.firebaseio.com",
//   projectId: "aigameboytest",
//   storageBucket: "aigameboytest.appspot.com",
//   messagingSenderId: "465314485661",
//   appId: "1:465314485661:web:b5863e6dac873e2a958b74",
//   measurementId: "G-M7BWRM08N3",
// };

console.log("using production firestore");
firebaseConfig = {
  apiKey: "AIzaSyAQ1FFV4thPlOGRubYkt0iEjycIWcgwJZE",
  authDomain: "aigamevue.firebaseapp.com",
  projectId: "aigamevue",
  storageBucket: "aigamevue.appspot.com",
  messagingSenderId: "940930884552",
  appId: "1:940930884552:web:e72569e34028b22895d6fd",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const rtdb = getDatabase(app);

export { db, auth, rtdb };

<template>
    <div class="min-h-screen bg-gray-50">
      <div class="fixed top-0 left-0 right-0 z-50 p-4 bg-gray-800 text-white">
        <h1 class="text-2xl font-bold">{{ policyTitle }}</h1>
        <p class="text-sm">&copy; {{ currentYear }} Doodles2Life LLC - All rights reserved.</p>
        <router-link :to="{ name: 'Login' }">
          <span class="color-primary cursor-pointer">Return to Game</span>
        </router-link>
      </div>
      <main class="pt-24 pb-4 px-4">
        <div class="prose max-w-full">
          <!-- Insert your privacy policy text here -->
          <h2>Last updated: 2/15/2023</h2>

<p><strong>Doodles2Life LLC</strong> ("us", "we", or "our") operates <strong>Charaids.com</strong> (the "Service").</p>

<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>

<p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from [Your Web App].</p>

<h3>Information Collection And Use</h3>

<p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>

<h4>Types of Data Collected</h4>

<p><strong>Personal Data</strong></p>

<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:</p>

<ul>
  <li>Email address</li>
  <li>First name and last name</li>
  <li>Facebook ID</li>
  <li>Other data that you voluntarily provide to us</li>
</ul>

<p><strong>Usage Data</strong></p>

<p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").</p>

<p>This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.</p>

<h3>Use of Data</h3>

<p>We use the collected data for various purposes:</p>

<ul>
  <li>To provide and maintain our Service</li>
  <li>To notify you about changes to our Service</li>
  <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
  <li>To provide customer support</li>
  <li>To gather analysis or valuable information so that we can improve our Service</li>
  <li>To monitor the usage of our Service</li>
  <li>To detect, prevent and address technical issues</li>
</ul>

<h3>Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)</h3>


        </div>
      </main>
      
    </div>
  </template>
  
  <script setup>
  import { computed } from "vue";
  import { useRouter } from "vue-router";
  
  const polProps = {
    companyName: {
      type: String,
      required: true,
    },
    policyTitle: {
      type: String,
      required: true,
    },
  };
  
  const currentYear = computed(() => new Date().getFullYear());
  const router = useRouter();
  
  function returnToGame() {
    router.push("/");
  }
  </script>
  
  <style>
  .prose h1,
  .prose h2,
  .prose h3 {
    margin-top: 2.5rem;
  }
  
  .prose h1:first-of-type,
  .prose h2:first-of-type,
  .prose h3:first-of-type {
    margin-top: 0;
  }
  
  .prose ul,
  .prose ol {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  
  .prose a {
    color: #3182ce;
    text-decoration: underline;
  }
  
  .prose pre code {
    padding: 0.25rem 0.5rem;
    background-color: #f5f7ff;
    border-radius: 0.25rem;
  }
  
  .color-primary {
    color: #3182ce;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  </style>
  
<template>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal">
      <transition name="modal-animation-inner">
        <div class="modal-content">
          <div class="modal-content-inner">
            <div class="wrapper">
              <span class="close-icon" @click="close">&times;</span>
              <slot />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>
<script>
export default {
  props: ['modalActive'],
  setup(props, { emit }) {
    const close = () => {
      emit('close');
    };
    return { close };
  },
};
</script>

  
  <style>
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 0;
    margin: 0;
  }
  
  .modal-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding-top: 10px;
  }
  
  .modal-content-inner {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  
  .full-size-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    margin: auto;
    width: auto !important;
    height: auto !important;
    align-self: flex-start !important;
    object-position: top;
    vertical-align: top !important;
  }
  
  .modal-animation-enter-active,
  .modal-animation-leave-active,
  .modal-animation-inner-enter-active,
  .modal-animation-inner-leave-active {
    transition: all 0.3s ease-out;
  }
  
  .modal-animation-enter,
  .modal-animation-leave-to,
  .modal-animation-inner-enter,
  .modal-animation-inner-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .modal-animation-enter-active,
  .modal-animation-inner-enter-active {
    opacity: 1;
    transform: scale(1);
  }
  
  .modal-animation-leave-active,
  .modal-animation-inner-leave-active {
    opacity: 0;
    transform: scale(0.9);
  }
  .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
}
.wrapper {
  position: relative;
}
  </style>
  
<template>
   <modal @close="toggleModalActive" :modalActive = "modalActive">
         <div class="modal-content">
            <img :src="selectedImg" class=" full-size-image" @click="toggleModalActive" />
         </div>
      </modal>
   <div v-show="!modalActive">  
	<div class="container mt-2 md:mt-0 mx-auto px-8">
      
     
		<div class="flex justify-between items-center" >
			<div class="sub-heading-3 p-1 md:flex flex-col relative w-max text-color tracking-widest" style="display: flex; align-items: center">
				<span>T-{{timer.minutes.value.toString().padStart(2, '0')}}:{{timer.seconds.value.toString().padStart(2, '0')}}</span>
			</div>
			<h1 class="sub-heading-3 small-text-2 color-pink text-center  title-name-text" style="flex: 1">
           {{storeGame.round1.movieTitle}}
        </h1>
			<h1 v-show="storeGame.round1Images?.length === 0 " class="sub-heading-4 text-sm-2 text-color text-center xl:text-right movie-title-text mt-[1px]" style="display: flex; align-items: center">
				<button @click="getNewMovie"  type="button" class="text-white bg-btn-primary border-2 p-1 border-white  inline-block" v-show="!gettingNewMovie && newMovieValid" >Change Movie</button>
				<div class="lds-facebook" v-show="gettingNewMovie">
					<div></div>
					<div></div>
					<div></div>
				</div>
			</h1>
		</div>
		<div class="text-center">
			<div class="flex justify-center">
				<div class="border-2 border-pink my-1 video-div relative" v-show="storeGame.currentImages?.length === 0 && storeGame.round1Images?.length === 0">
					<div v-show="showPlayIcon" class="absolute inset-0 flex items-center justify-center"></div>
					<div class="youtube-container" v-show="playerEngaged" >
						<LiteYouTubeEmbed :id="youtubeCode" :title="storeGame.round1.movieTitle" ref="iframe" />
					</div>
					<div style="display: flex; justify-content: center; align-items: center; position: relative;">
						<img :src="storeGame.round1.movieImage" alt="" style="object-fit: contain; width: 100%; height: 300px; margin: 0 auto;" v-show="!playerEngaged" class="movie-image">
							<img @click="playerEngage" src="../assets/pngegg.png" alt="" style="height: 40px; width: auto; position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" v-show="!playerEngaged">
							</div>
						</div >
					</div>
				</div>
			</div>
			<div class="container mx-auto text-center mt-2 md:mt-8 mb-2 px-2">
				<div v-show="storeGame.currentImages.length == 0">
					<h1 class=" small-text text-color tracking-widest" v-show="storeGame.round1Images.length
						<3 && !storeGame.generatingImages && storeGame.numOfPhases > 0">ENTER PROMPT FOR IMAGE CREATION 
						</h1>
						<h1 class="xwords color-red text-color tracking-widest" v-show="storeGame.round1Images.length
							<3 && !storeGame.generatingImages && storeGame.numOfPhases > 0">
								<span class="penalty-words-label">Penalty Words:</span>{{penaltyWordString}}
        
							</h1>
							<span class="color-blueseil sm:mt-2 lg:mt-0" style="font-size: 12px" v-show="storeGame.numOfPhases>0">Image Creations Remaining : {{ storeGame.numOfPhases }}</span>
							<div class="flex justify-center items-center" v-show="storeGame.round1Images.length
								<3 && !storeGame.generatingImages && storeGame.numOfPhases > 0">
									<textarea type="text" class="bg-transparent input-field my-4" v-model="text" style="height: 90px; width: 100%;" ref="textAreaRef" id="my-textarea"  placeholder="enter image description here"></textarea>
									<div class="ml-2 flex items-center">
										<button @click="checkPrompt" v-show="storeGame.round1Images.length
											<3 && !storeGame.generatingImages && storeGame.numOfPhases > 0" type="button" class="text-white bg-btn-primary border-3 p-3 border-white  inline-block">Go
											</button>
										</div>
									</div>
								</div>
								<div v-if="storeGame.generatingImages || storeGame.waitingForGuessing">
                           <div> 
                              <div class="lds-facebook">
										<div></div>
										<div></div>
										<div></div>
									</div>
                           </div>
									
									<span v-if="foundWords.length>0 && !storeGame.waitingForGuessing" class="text-red-600 font-bold block">
        The following words will result in a game score penalty: {{ foundWords.join(', ') }}
        </span>
        <div class="text-white   p-3 border-white  inline-block" v-show="storeGame.waitingForGuessing">waiting for all players to complete their images</div>
        <google-ad-sense></google-ad-sense>
								
								</div>
								<div></div>
								<!-- IF CONDITION FOR PROMPT ERROR -->
								<!-- <div class="mt-14 lg:mt-8"><h1 class="sub-heading-3 text-error tracking-widest my-8">
        Prompt must not include any excluded word!
        </h1></div> -->
								<!-- IF CONDITION ENDS HERE -->
								<div class=" items-end px-5 my-5 justify-items-center" >
									<div class="col-span-12 lg:col-span-8 order-first lg:order-none">
										<div class="my-2 ">
											<h1 class="text-sm text-color text-center" v-show="storeGame.currentImages && storeGame.currentImages.length > 0 && storeGame.round1Images?.length
												<3">PICK ONE
												</h1>
											</div>
											<div class="flex justify-center gap-x-2" v-show="storeGame.round1Images?.length < 3">
												<!-- <div class="game-img overflow-hidden" @click="showModal()"><img src="../assets/game-img-1.png" class="w-full h-full object-cover" /></div> -->
                        <div v-for="(image, index) in storeGame.currentImages" :key="index">
                          <div class="image-container" @click="selectImg(image.image, index)">
                            <img :src="image.image" class="w-full h-full object-cover fixed-size-image"  />
                            <img src="@/assets/binocular.png" class="image-icon" />
                          </div>
                          <div :id="`button-${image.image}`" class="block my-2">
                            <button v-show="selectedImageCount() < 3" type="button" class="color-pink border-pink border-3 p-1 normal-text w-full" @click="selectImage(image.image,index)"> PICK </button>
                          </div>
                        </div>

												</div>
											</div>
											<div class="col-span-12 lg:col-span-8 order-first lg:order-none ">
												<div class="my-2 ">
													<h1 class="text-sm text-color text-center" v-show="storeGame.round1Images && storeGame.round1Images.length > 0 && !storeGame.generatingImages" >{{ storeGame.round1Images?.length }}  / 3 Selected for Submission</h1>
												</div>
												<div class="flex justify-center gap-x-2" v-show="!storeGame.generatingImages">
													<!-- <div class="game-img overflow-hidden" @click="showModal()"><img src="../assets/game-img-1.png" class="w-full h-full object-cover" /></div> -->
													<div v-for="(image, index) in storeGame.round1Images" :key="index">
														<div >
															<img :src="image.image" class="w-full h-full object-cover fixed-size-image" />
														</div>
													</div>
												</div>
											</div>
										</div>
										<button @click="submit" v-show="storeGame.round1Images.length==3 && showSubmit===true" type="button" class="text-white bg-btn-primary border-3 p-3 border-white main-btn inline-block">SUBMIT</button>
									</div>
                        </div>
								</template>
                    
<script setup>
import modal from '@/views/components/modal.vue';

import GoogleAdSense from '../views/components/GoogleAdSense.vue';
import ImageModal from "../views/components/imageModal.vue";
import {
    ref,
    computed,
    reactive,
    watchEffect,
    onMounted,
    onUpdated,
    watch,
    onBeforeMount,
    defineComponent
    
} from 'vue';
import {
    useTimer
} from 'vue-timer-hook';
import {
    useStoreGame
} from '@/stores/storeGame'
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'
import 'vue-lite-youtube-embed/style.css'
import {
    useRouter, onBeforeRouteLeave
} from 'vue-router'

//components 

const modalActive = ref(false);
const toggleModalActive = () => {
   modalActive.value = !modalActive.value;
};
defineComponent({
    components: {
      GoogleAdSense,
    },
  });
const iframe = ref(null)
const router = useRouter();
const storeGame = useStoreGame();
const showImageModal = ref(false);
const showPlayIcon = ref(true);
const showSubmit = ref(true);
const currentImageLink = ref("");
const previousImageID = ref("");
const newMovieValid = ref(true);
const previousButtonID = ref("");
const text = ref('');
const archiveText = ref('')
const foundWords = ref([]);
const showWarning = ref(false);
const penaltyWords = ref(0)
const playerEngaged = ref(false);
const gettingNewMovie = ref(false);
const modalImg = ref('');

const selectedImg = ref('');

const selectImg = (imgUrl, index) => {
  selectedImg.value = imgUrl;
  //console.log(`Image ${index} was selected.`);
  toggleModalActive();
  // Open modal with selectedImg.value
}
const penaltyWordString = computed(() => {
  const uniqueWords = [...new Set((storeGame.round1.excludedWords || []).map(word => word.replace(/^\d{1,2}\s*/, '')))];
  return uniqueWords.join(', ');
});



const textAreaRef = ref(null);

const playerEngage = () => {
    playerEngaged.value = true;
    iframe.value?.playVideo;

}



const components = {
    modal,
};

const time = new Date();
time.setSeconds(time.getSeconds() + 600); // 10 minutes timer
const timer = useTimer(time);
//console.log('timerobj :', timer.minutes.value.toString())


onMounted(() => {
    watchEffect(async () => {
        if (timer.isExpired.value) {
            console.warn('IsExpired');
            
            await submit();
            storeGame.allUsersComplete = true;
            confirmNavigation.value=true;
            setTimeout(async () => {
    await router.push({ name: 'GuessTitle' });
}, 5000); // 5000 milliseconds = 5 seconds

        }
    })
})


const youtubeCode = computed(() => {
    // Extract the video code from the video URL
    if (storeGame.round1.trailerUrl) {
        const match = storeGame.round1.trailerUrl.match(/(\?|&)v=([^&#]+)/);
        //console.log(match)
        return match ? match[2] : null;
    }
});


const selectedImageCount = () => {

    return (storeGame.round1Images?.length || 0)
}

async function getNewMovie() {
  gettingNewMovie.value = true;
  await storeGame.getNewMovie();
  setTimeout(() => {
    if (iframe?.value) {
      iframe.value.stopVideo();
    }
  }, 1000);
  gettingNewMovie.value = false;
  playerEngaged.value = false;
}

const selectImage = (img, index) => {
  // Convert the URL string to an object
  const pushimage = {
    image: img,
    promptTxt: storeGame.currentPrompt,
    penaltyWords: penaltyWords.value,
  };

  // Add the object to the selectedImages array using Pinia
  storeGame.round1Images.push(pushimage);
  storeGame.currentImages = [];
};



async function checkPrompt() {
  try {
    newMovieValid.value=false;
    storeGame.generatingImages = true;
    const words = text.value.split(/[^a-zA-Z0-9]+/);
    foundWords.value = findPenaltyWords(words, penaltyWordString.value);
    showWarning.value = foundWords.value.length > 0;
    penaltyWords.value = foundWords.value.length || 0;
    storeGame.currentPrompt = text.value;
    storeGame.numOfPhases = storeGame.numOfPhases - 1;
    archiveText.value = text.value;
    text.value = '';
    setTimeout(() => {
    if (iframe?.value) {
      iframe.value.stopVideo();
    }
  }, 1000);
    await storeGame.submitPrompt();
    storeGame.currentImages = [];
    showWarning.value = false;
  } catch (error) {
    console.error(error);
  }
}

function findPenaltyWords(words, penaltyWordString) {
  const uniquePenaltyWords = [...new Set(penaltyWordString.split(/[^a-zA-Z0-9]+/))]
    .filter(word => word !== "")  // exclude empty string
    .map(word => word.toLowerCase());
  return words.filter(word => uniquePenaltyWords.includes(word.toLowerCase()));
}





const submit = async () => {
  // don't do the next thing if it's been done before
  showSubmit.value = false;
  if (storeGame.playerImgSubManual === false) {
    await storeGame.submitImages();
    storeGame.playerImgSubManual = true;
  } else {
    console.log('Images have already been submitted manually. Skipping now.');
  }
 
}
const confirmNavigation = ref(false);
watchEffect(() => {
  if (storeGame.playerImgSubmissions.length > 0) {
   confirmNavigation.value=true;
    router.push({
      name: 'GuessTitle'
    });
  }
});


onBeforeRouteLeave((to, from, next) => {
  if (confirmNavigation.value) {
    // Allow the navigation to proceed
    next();
  } else {
    // Prevent the navigation and display a confirmation dialog
    const message = 'Are you sure you want to leave the game?';
    if (confirm(message)) {
      // Navigate to a different page when the player confirms the navigation 
      //stub to remove player from game
      confirmNavigation.value = true;
      router.push({ name: 'StartGame' });
    } else {
      next(false);
    }
  }
});
</script>
<style>
mark {
  background-color: yellow;
}
.text-red-600 {
    color: red;
    font-weight: bold;
  }
  .border-pink {
  border-color: #e74c3c;
  overflow: hidden;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.youtube-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain; /* or "cover" to crop the thumbnail */
}
.new-movie-img {
    width: 140px;
    height: 50px;
    margin: 4px;
    padding: 4px;
   
  }

  @media (max-width: 768px) {
    .new-movie-img {
      width: 30px;
      height: 25px;
    }
  }
  .fixed-size-image {
  width: 150px;
  height: 150px;
}
.small-text {
  font-size: 1.5rem;
}
textarea {
  font-size: 10px;
  line-height: 1.25 !important;
}
.fixed-size-image {
    width: 150px !important;
    height: auto;
}
.main-btn {
  width: auto !important;
  font-size: 10px;
}
.xwords {
  font-size: 10px !important;

}
.penalty-words-label {
  color: rgb(162, 162, 207);
}
@media (max-width: 767px) {
    .movie-image {
      height: 190px !important;
    }
  }
  .image-container {
  position: relative;
}

.image-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
}


</style>
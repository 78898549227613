<template>
  <div class="container mx-auto px-8">
    <div class="mt-4 md:mt-8 text-center">
      <div class="flex flex-col items-center mb-5 md:mb-8">
        <img src="../assets/handvictor-8.png" class="w-max" />
        <img src="../assets/Title.png" class="w-1/2 md:w-1/4 mt-4" />
      </div>

      <div>
        <h1 class="sub-heading text-color tracking-widest">LOGIN TO START</h1>
      </div>

      <!-- IF CONDITION FOR LOGIN ERROR -->
      <!-- <div>
        <h1 class="sub-heading text-error tracking-widest">WRONG INFO!</h1>
      </div> -->
      <!-- IF CONDITION ENDS HERE -->

      <div class="pt-3">
        <div class="my-5">
          <input
            type="text"
            placeholder="Email"
            class="bg-transparent input-field"
            v-model="credentials.email"
          />
        </div>

        <div class="my-5">
          <input
            
            type="password"
            placeholder="Password"
            class="bg-transparent input-field"
            v-model="credentials.password"
          />
        </div>

        <div class="my-5">
          <router-link  :to="{ name: 'StartGame' }">
            <button @click="onSubmit"
              type="button"
              class="text-white bg-btn-primary border-4 p-3 border-white main-btn font-normal w-full md:w-auto"
            >
              START!
            </button>
          </router-link>
        </div>
      </div>
      <div class="my-4">
        <span
          class="sub-heading-3 text-color tracking-wide py-5 cursor-pointer hover:font-bold 
          :font-normal"
          >Forgot password?
        </span>
        <div><span
          class="sub-heading-3 text-color tracking-wide py-5  active:font-normal" v-show="storeAuth.loginError"
          > Error with login, have you signed up? (see below)
        </span> </div>
        
      </div>

      <div>
        <h1 class="sub-heading-2 text-color tracking-wide py-2">OR</h1>
      </div>

      <div class="mb-10"  @click="onGSubmit">
        <span
          class="sub-heading-2 text-color tracking-wide py-5 cursor-pointer hover:font-bold active:font-normal"
        >
          Login with
          <span>
            <span class="color-blue sub-heading-2 text-color tracking-wide"
              >G</span
            ><span class="color-red">o</span><span class="color-yellow">o</span
            ><span class="color-blue">g</span><span class="color-green">l</span
            ><span class="color-red">e</span>
          </span>
        </span>
      </div>

      <!-- <div class="my-4">
        <span
          class="sub-heading-2 text-color tracking-wide py-5 cursor-pointer hover:font-bold active:font-normal"
        >
          Login with <span class="color-blue">Facebook</span>
        </span>
      </div> -->

      <div class="my-4">
        <span
          class="sub-heading-2 text-color tracking-wide py-5 cursor-pointer hover:font-bold active:font-normal"
        >
          Don't have an account?
          <router-link  :to="{ name: 'CreateAccount' }">
            <span class="color-primary cursor-pointer">SIGN UP</span>
          </router-link>
        </span>
      </div>
      <div class="my-4">
        <span
          class="sub-heading-2 text-color tracking-wide py-5 cursor-pointer hover:font-bold active:font-normal"
        >
          Want to read our privacy policy?
          <router-link  :to="{ name: 'ReadPolicy' }">
            <span class="color-primary cursor-pointer">Here</span>
          </router-link>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, reactive } from 'vue'
  import { useStoreAuth } from '@/stores/storeAuth'
    /*
    store
  */

  const storeAuth = useStoreAuth()

  /*
    register / login
  */

    const register = ref(false)

  /*
    form title
  */



  const loginSubmission = ref(true);
  const form = ref({ username: '', password: '' });
  const credentials = reactive({
    email: '',
    password: ''
  })
  function login() {
    // Your login method code here
  }
  const onSubmit = () => {
    if (!credentials.email || !credentials.password) {
      alert('Please enter an email and password gosh darnit!')
    }
    else {
      if (register.value) {
        storeAuth.registerUser(credentials)
      }
      else {
        //console.log('credentials: ', credentials)
        storeAuth.loginUser(credentials)
      }
    }
  }
  const onGSubmit = () => {
    storeAuth.googleloginUser()
  }
  
</script>

<style scoped></style>

import { defineStore } from "pinia";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  onIdTokenChanged,
  PhoneAuthProvider,
} from "firebase/auth";
import {
  doc,
  setDoc,
  serverTimestamp,
  getFirestore,
  Timestamp,
} from "firebase/firestore";

import avatarImages from "@/js/index";
import { db, auth, rtdb } from "@/js/firebase";
//import { useStoreNotes } from '@/stores/storeNotes'
import {
  getDatabase,
  ref,
  onValue,
  set,
  onDisconnect,
} from "firebase/database";

export const useStoreAuth = defineStore("storeAuth", {
  state: () => {
    return {
      user: {},
      loginError: false,
    };
  },
  actions: {
    init() {
      //const storeNotes = useStoreNotes()
      onIdTokenChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((token) => {
            auth.currentUser
              .getIdToken(true)
              .then((refreshedToken) => {
                console.log("Token Refreshed");
              })
              .catch((error) => {
                console.log("error refreshing token", error);
              });
          });
        }
      });
      onAuthStateChanged(auth, (user) => {
        console.log("onAuthStateChanged in storeAuth Fired", user);
        if (user) {
          this.user.id = user.uid;
          this.user.email = user.email;
          const avatars = avatarImages.map((avatar) => avatar.url);
          const randomAvatar =
            avatars[Math.floor(Math.random() * avatars.length)];

          this.user.photoURL = randomAvatar;
          const uid = user.uid;
          console.log("setting login data to rt db");
          const userStatusDatabaseRef = ref(rtdb, `/status/${uid}`);
          const userStatusFirestoreRef = doc(db, `/status/${uid}`);
          const serverTimestamp = Timestamp.fromDate(new Date());

          const isOfflineForDatabase = {
            state: "offline",
            last_changed: serverTimestamp,
          };

          const isOnlineForDatabase = {
            state: "online",
            last_changed: serverTimestamp,
          };

          const isOfflineForFirestore = {
            state: "offline",
          };

          const isOnlineForFirestore = {
            state: "online",
          };

          onValue(ref(rtdb, ".info/connected"), async (snapshot) => {
            if (snapshot.val() == false) {
              await setDoc(userStatusFirestoreRef, isOfflineForFirestore);
              return;
            }

            try {
              await onDisconnect(userStatusDatabaseRef).set(
                isOfflineForDatabase
              );
              await set(userStatusDatabaseRef, isOnlineForDatabase);
              await setDoc(userStatusFirestoreRef, isOnlineForFirestore);
            } catch (error) {
              console.log("error a setting user status", error);
            }
          });
          //this.user.photoURL = user.photoURL;
          this.user.displayName = user.displayName;
          this.router.push("/startgame");
          //storeNotes.init()
        } else {
          this.user = {};
          this.router.replace("/");
          // storeNotes.clearNotes()
        }
      });
    },
    registerUser(credentials) {
      console.log("registering the new user now with: ", credentials);

      createUserWithEmailAndPassword(
        auth,
        credentials.email,
        credentials.password
      )
        .then((userCredential) => {
          const user = userCredential.user;
          //assign a random avatar

          console.log("user: ", user);
        })
        .catch((error) => {
          console.log("error.message: ", error.message);
        });
    },
    loginUser(credentials) {
      this.loginError = false;
      signInWithEmailAndPassword(auth, credentials.email, credentials.password)
        .then((userCredential) => {
          const user = userCredential.user;

          console.log("user: ", user);
        })
        .catch((error) => {
          console.log("error.message: ", error.message);
          this.loginError = true;
        });
    },
    googleloginUser() {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider)
        .then((userCredential) => {
          const user = userCredential.user;
          // console.log('user: ', user)
        })
        .catch((error) => {
          // console.log('error.message: ', error.message)
        });
    },
    logoutUser() {
      signOut(auth)
        .then(() => {
          // console.log('User signed out')
        })
        .catch((error) => {
          // console.log(error.message)
        });
    },
  },
});

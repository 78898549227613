<template>
  <modal @close="toggleModalActive" :modalActive="modalActive">
      <div class="modal-content"> <img :src="selectedImg" class=" full-size-image" @click="toggleModalActive" /> </div>
  </modal>
  <div v-show="!modalActive">
      <div class="mt-0 mx-auto px-8">
          <div class="grid grid-cols-31 gap-2 items-center md:grid-cols-3" v-show="!showDonate" >
              <div class="justify-self-end hidden md:block"> <img src="../assets/handvictor-8.png" class="w-max rotate-90" /> </div>
              <div class="justify-self-start md:justify-self-center flex flex-row md:flex-col items-center">
                  <div class="player-turn-img m-4"> <img :src="thisItemAvatar" class="h-full maintain-aspect-ratio-avatar" /> </div>
                  <div class="text-center">
                      <h1 class="sub-heading-3 small-text text-color" v-show="!gettingScore"> <span class="color-blueseil">{{thisItemDisplayName || null}}</span><span class="color-blueseil">'s submission - {{ thisItem.movie }}</span> </h1>
                      <div class="lds-facebook" v-show="gettingScore">
                          <div></div>
                          <div></div>
                          <div></div>
                      </div>
                  </div>
              </div>
              <div class="justify-self-start hidden md:block"> <img src="../assets/handvictor-8.png" class="w-max -rotate-90" /> </div>
          </div>
      </div>
      <div class="container mt-0 md:mt-8 mx-auto px-8">
          <div class="flex justify-center items-center gap-x-8" v-show="showPhotos && !showDonate">
              <div v-for="(image, index) in thisItem.images" :key="index" class="title-img overflow-hidden"> <img :src="image.image" @click="selectImg(image.image, index)" class="w-full md:w-auto max-h-32 md:max-h-full" /> </div>
          </div>
      </div>
      <div class="container mt-3.5 md:mt-8 mx-auto px-8">
          <div class="text-center" v-show="!showDonate">
              <div>
                  <div class="flex justify-center items-center">
                      <table class="table">
                          <thead>
                              <tr>
                                  <th class="small-text text-color">Player</th>
                                  <th class="small-text text-color">Guess</th>
                                  <th class="small-text text-color">Score</th>
                                  <th class="small-text text-color">WORD Penalty</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(score, index) in scoreArray" :key="index">
                                  <td class="text-color">{{ score.player }}</td>
                                  <td class="text-color">{{ score.guess }}</td>
                                  <td class="text-color">{{ score.score }}</td>
                                  <td class="text-color">{{ score.penalty }}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div> <button type="button" class="text-white bg-btn-primary border-3 px-10 py-3 border-white w-auto" @click="getNextSet()" :style="{ 'margin-top': '4px' }" v-show="thereIsMore"> NEXT ARTIST </button> <button type="button" class="text-white bg-btn-primary border-3 px-10 py-3 border-white w-auto" @click="getNextSet()" :style="{ 'margin-top': '4px' }" v-show="!thereIsMore"> SEE SUMMARY </button>
              </div>
          </div>
          <div class="text-center" v-show="showDonate">
      <h2 class="text-color">GAME SUMMARY</h2>
      <div>
        <div class="flex justify-center items-center">
          <table class="table">
            <thead>
              <tr>
                <th class="small-text text-color">Player</th>
                <th class="small-text text-color">Score</th>
                <th class="small-text text-color">PENALTY</th>
                <th class="small-text text-color">TOTAL SCORE</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(score, index) in scoreSummaryArray" :key="index">
                <td class="text-color">{{ score.displayName }}</td>
                <td class="text-color">{{ score.score }}</td>
                <td class="text-color">{{ score.penalty }}</td>
                <td class="text-color">{{ score.score - score.penalty }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-center">
        <p class="text-color">Congratulations {{highestScorer }}</p>
        <img :src="highestScorerAvatar" alt="Winning Player" width="200" height="200" class="mx-auto">
        <p class="text-color">WINNING SCORE: {{ highestScore }}</p>
      </div>
    </div>
          <div class="text-center" v-show="showDonate">
            <button type="button" class="text-white bg-btn-primary border-3 px-10 py-3 border-white w-auto" @click="newGame()" :style="{ 'margin-top': '4px' }"> PLAY AGAIN </button>
            <br>
            <button type="button" class="text-white bg-btn-primary border-3 px-10 py-3 border-white w-auto" @click="feedback()" :style="{ 'margin-top': '4px' }"> PROVIDE FEEDBACK OR BUGS</button>
            <div style="display: flex; justify-content: center; margin-top: 4px;">
              <iframe src="https://www.paypal.com/giving/campaigns?campaign_id=2RQ7UMFJSNLT6" title="PayPal donate campaign card" frameborder="0" width=300 height=600 scrolling="no"></iframe>
            </div>
</div>

      </div>
  </div>
</template>
 
 <script setup>
   import {
     ref,
     computed,
     reactive,
     watchEffect,
     onMounted,
     onUpdated,
     watch,
     onBeforeMount,
     toRefs
   } from 'vue';
   import {
     useTimer
   } from 'vue-timer-hook';
   import {
     useStoreGame
   } from '@/stores/storeGame'
   import modal from '@/views/components/modal.vue';
   
   //import 'vue-lite-youtube-embed/style.css'
   import {  useRouter, onBeforeRouteLeave } from 'vue-router'
   import {useStoreAuth} from '@/stores/storeAuth'

   const modalActive = ref(false);
   const toggleModalActive = () => {
    modalActive.value = !modalActive.value;
 };
   const showPhotos = ref(true);
   const myStoreAuth = useStoreAuth();
   
   const headerText = ref('GUESSES');
   const showScore =ref(false);
   const showDonate = ref(false);
   const gettingScore = ref(false);
   const showPrompt = ref(true);
   const router = useRouter();
   const storeGame = useStoreGame();
   const scoreResponse= ref('');
   const guess = ref('');
   const guessMade = ref('false');
 
   const dataset = storeGame.playerImgSubmissions;
   const dataLength = dataset.length;
   const imgUser = storeGame.playerImgSubmissions[1].user;
   
   const thisItem = ref(dataset[0]);

   //console.log ('this items user:', thisItem.value.user);
   //console.log ('current user ', myStoreAuth.user.id);
   
   const thereIsMore = computed(() => {
     return dataset.indexOf(thisItem.value)+1 !== dataLength;
   });
   
   const selectedImg = ref('');
 
 const selectImg = (imgUrl, index) => {
   selectedImg.value = imgUrl;
   //console.log(`Image ${index} was selected.`);
   toggleModalActive();
   // Open modal with selectedImg.value
 }
   
 const getNextItem = () => {
  //console.log('running next');
  let index = dataset.indexOf(thisItem.value) + 1;
  if (index < dataLength) {
    thisItem.value = dataset[index];
    //console.log('getting next: not at end');
    return true;
  } else {
    //console.log('reached the end');
    return false;
  }
};

const feedback = () => {
  window.open('https://forms.gle/ShG8VBvPNrTZWPn58', '_blank');
}

   
   const thisItemAvatar = computed(() => {
     const player = storeGame.game.players.find(player => player.id === thisItem.value.user);
     return player ? player.photoURL : null;
   });
   
   const thisItemDisplayName = computed(() => {
     const player = storeGame.game.players.find(player => player.id === thisItem.value.user);
     if (player) {
       return player.displayName || player.email.split('@')[0];
     } else {
       return null;
     }
   });
 
   const thisItemUserId = computed(() => {
     const player = storeGame.game.players.find(player => player.id === thisItem.value.user);
     if (player) {
       return player.id;
     } else {
       return null;
     }
   });
   
   const submitGuess = async (guessText) => {
     gettingScore.value=true;
     showPhotos.value = false;
     showPrompt.value=false;
     //console.log('guess: ', guessText);
     const actualMovieTitle = thisItem.value.movie;
     //console.log('movie: ', actualMovieTitle);
     const thisguess = {
       actualMovieTitle,
       guessText,
     };
     const result = await storeGame.processGuess(thisguess);
     
     showScore.value=true;
     const parsedResult= result;
     headerText.value = 'Actual Movie:\n ' + actualMovieTitle + '\n You Scored: ' + parsedResult.score
     //console.log('result from chatgpt into the vue component: ',result);
     scoreResponse.value = parsedResult.reason; 
     //console.log(guess.value);
     gettingScore.value=false;
     const imgUserPenalty = computePenalty();
 
     //add any future bonus scores / penalties here 
     const scoreInfo = {
       guessUser: myStoreAuth.user.id,
       imgUser: thisItemUserId,
       guess: guessText,
       score: parsedResult.score,
       imgUserPenalty : imgUserPenalty
 
     }
     storeGame.scores.push(scoreInfo)
 
 
     // set the v-model of the input field to the value returned by processGuess
   }
 
   const computePenalty = () => {
     let penalty = 0;
     //console.log('images from this itme:', thisItem.value.images.length);
     for (let image of thisItem.value.images) {
   penalty += image.penaltyWords;
 }
 
     return penalty
   }
 
   const getNextSet = async () => {
     //console.log('item index now before more = ', dataset.indexOf(thisItem.value));
     //get the next players images if any exist
     const more = getNextItem();
     //console.log('item index now after more = ', dataset.indexOf(thisItem.value));
     //console.log('more', more);
     if (more) {
     showPrompt.value=true;
     showScore.value=false;
     headerText.value= 'GUESSES';
     guess.value='';
     scoreResponse.value = '';
     showPhotos.value=true;
     } else {
       // user has guess all available other players' movies
       //time to send data to firestore so that all users will be able to see scores
       headerText.value ='Thanks for Playing';
       scoreResponse.value = 'Thats all the further I have gotten so far, come back for better versions'
       showDonate.value=true;
       confirmNavigation.value = true;
      
     }
   }
 
   const newGame = () => {
     confirmNavigation.value=true;
     storeGame.allUsersComplete=false;
     router.push({name: 'StartGame'});
   }
   const confirmNavigation = ref(false);
 onBeforeRouteLeave((to, from, next) => {
   if (confirmNavigation.value) {
     // Allow the navigation to proceed
     next();
   } else {
     // Prevent the navigation and display a confirmation dialog
     const message = 'Are you sure you want to leave this page?';
     if (confirm(message)) {
       // Navigate to a different page when the player confirms the navigation
       confirmNavigation.value = true;
       router.push({ name: 'StartGame' });
     } else {
       next(false);
     }
   }
 });

 const scoreArray = computed(() => {
  //console.log ('starting to configure the scoreArray');
  //console.log ('I have this many scores: ', storeGame.allScores.length)
  //console.log('the current artists on the screen is :', thisItemDisplayName.value);

  let myarray = [];
  let myCount = 0;
  const thisImgUser = thisItem.value.user;
  console.log('the thisImgUser is: ', thisImgUser);
  for (let score of storeGame.allScores) {
  console.log('starting the loop of scores now');
  console.log('examing if these two match', score.score.imgUser, ' and ', thisImgUser)
    if (score.score.imgUser == thisImgUser) {
      console.log('match')
      myCount = myCount + 1;
    
      const player = storeGame.game.players.find(player => player.id === score.score.guessUser);
      const displayName = player.displayName || player.email.split('@')[0];
      const scoreObj = {
      player : displayName,
      guess: score.score.guess,
      score: score.score.score,
      penalty: score.score.imgUserPenalty
      
    }
    myarray.push(scoreObj);
    } else {
      console.log('no match')
    }
  }
  return myarray
 
});

const highestScore = ref(0);
const highestScorer = ref('');
const highestScorerAvatar = ref('');

const scoreSummaryArray = computed(() => {
  const summaryScore = {};

  storeGame.allScores.forEach(score => {
    const player = score.player;
    const imgUser = score.score.imgUser;
    const penalty = score.score.imgUserPenalty;
    const scoreValue = score.score.score;

    // Update player score
    if (summaryScore[player]) {
      summaryScore[player].score += scoreValue;
    } else {
      summaryScore[player] = { score: scoreValue, penalty: 0 };
    }

    // Update imgUser score and penalty
    if (summaryScore[imgUser]) {
      summaryScore[imgUser].score += scoreValue;
      summaryScore[imgUser].penalty += penalty * 10;
    } else {
      summaryScore[imgUser] = { score: scoreValue, penalty: penalty * 10 };
    }
  });

  // Add player, displayName, score, penalty, and totalScore properties to each item in the array
  const summaryArray = Object.keys(summaryScore).map(player => {
    const score = summaryScore[player];
    const playerObj = storeGame.game.players.find(p => p.id === player);
    const displayName = playerObj ? playerObj.displayName || playerObj.email.split('@')[0] : player;
    const totalScore = score.score - score.penalty;
    
    // Update highest score and scorer
    if (totalScore > highestScore.value) {
      highestScore.value = totalScore;
      highestScorer.value = displayName;
      highestScorerAvatar.value = playerObj ? playerObj.photoURL : null;
    }

    return { player, displayName, score: score.score, penalty: score.penalty, totalScore };
  });

  return summaryArray; //hope
});






   
 </script>
 
 
 <style>
  .table {
    border-collapse: collapse;
    margin: auto;
    width: 95%;
  }

  .table th,
  .table td {
    padding: 0.5rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
    word-wrap: break-word;
  }

  .table th {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .small-text {
    font-size: 0.8rem;
  }

  .text-color {
    color: #007bff;
  }

  /* Media query for small screens */
  @media screen and (max-width: 576px) {
    .table td,
    .table th {
      font-size: 0.7rem;
    }
  }

  @media screen and (min-width: 577px) and (max-width: 768px) {
    .table td,
    .table th {
      font-size: 0.8rem;
    }
  }

  @media screen and (min-width: 769px) {
    .table td,
    .table th {
      font-size: 1rem;
    }
  }
  .player-turn-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
}
@media screen and (max-width: 576px) {
  .player-turn-img {
    width: 60px;
    height: 60px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .player-turn-img {
    width: 80px;
    height: 80px;
  }
}

.maintain-aspect-ratio-avatar {
  object-fit: cover;
  width: 100%;
}

</style>
